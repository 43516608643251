<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    AD TEMPLATES
                </div>
                <div class="page_head-total-btn">
                    {{total_point}} Total
                </div>
            </div>
            <div class="page_head-item">
<!--                <div class="search_loop">-->
<!--                    <b-form-input  class="mr-sm-2" placeholder="Search Leads" v-model="keyword"></b-form-input>-->
<!--                    <div class="s_loop"></div>-->
<!--                </div>-->
                <div class="mx-3">
                    <b-form-select v-model="selectedLeadType">
                        <template #first>
                            <b-form-select-option value="">All Leads</b-form-select-option>
                        </template>
                        <option
                                v-for="(option, id) in leadType"
                                :key="id"
                                :value="option.id"
                                :title="option.type || null"
                        >
                            {{option.type}}
                        </option>
                    </b-form-select>
                </div>
                <div>
                    <b-button class="page_head-new-btn" @click="chooseTemplate()" id="lead-btn">Add New Ad Template</b-button>
                </div>
            </div>
        </div>

        <div class="page_content">
            <b-table hover :items="template_data" :fields="fields" responsive="sm">
                <template v-slot:cell(template_name)="data">
                    <div class="item_name item_center">
                        {{data.item.template_name}}
                    </div>
                </template>
                <template v-slot:cell(type)="data">
                    <div class="item_center">
                        {{data.item.type}}
                    </div>
                </template>
                <template v-slot:cell(unique)="data">
                    <div class="item_center">
                        {{getData(data.item.unique)}}
                    </div>
                </template>
                <template v-slot:cell(leads)="data">
                    <div class="item_center">
                        {{getData(data.item.leads)}}
                    </div>
                </template>
                <template v-slot:cell(conv)="data">
                    <div class="item_center">
                        {{getData(data.item.conv)}}
                    </div>
                </template>
                <template v-slot:cell(source)="data">
                    <div class="item_center">
                        {{data.item.source}}
                    </div>
                </template>

                <template v-slot:cell(view)="data">
                    <div class="item_view" id="template_view" @click="templateViewModal(data.item)">
                    </div>
                </template>
                <template v-slot:cell(edit)="data">
                    <div class="item_edit" id="template_edit-btn" @click="templateEditModal(data.item)">
                    </div>
                </template>
                <template v-slot:cell(delete)="data">
                    <div class="item_delete" id="template_delete-btn" @click="templateDeleteModal(data.item)">
                    </div>
                </template>
            </b-table>
        </div>
<!--Template delete modal start-->
        <b-modal ref="template_delete" centered hide-header hide-footer hide-header-close>
            <div class="template-delete_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="deleteTemplate()"></b-icon>
                <div class="d-flex">
                    <div class="template-delete_title">
                        You are about to delete this template
                    </div>
                </div>
                <div class="template-delete_message">
                    Do you want to proceed?

                </div>

                <div class="delete_btn-container">
                    <b-button class="delete_btn" @click="removeTemplate()">Delete</b-button>
                </div>
            </div>
        </b-modal>
<!--Template delete modal end-->
    </div>
</template>

<script>
    export default {
        name: "AdTemplates",
        data() {
            return {
                borderPosition: true,
                total_point: 4,
                selectedLeadType: '',
                leadType: [
                    {
                        type: 'Seller',
                        id: 0
                    },
                    {
                        type: 'Buyer',
                        id: 1
                    }
                ],
                fields: [
                    {
                        key: 'template_name',
                        label: 'NAME',
                    },
                    {
                        key: 'type',
                        label: 'TYPE',
                    },
                    {
                        key: 'unique',
                        label: 'UNIQUE VIEWS',
                    },
                    {
                        key: 'leads',
                        label: 'LEADS',
                    },
                    {
                        key: 'conv',
                        label: 'CONV %',
                    },
                    {
                        key: 'source',
                        label: 'TOP SOURCE',
                    },
                    {
                        key: 'view',
                        label: 'VIEW',
                    },
                    {
                        key: 'edit',
                        label: 'EDIT'
                    },
                    {
                        key: 'delete',
                        label: 'DELETE',
                    },

                ],
                template_data: [
                    {
                        template_name: 'Buyer Campaign - Luxury Homes',
                        type: 'Home Valuation',
                        unique: 64,
                        leads: 0,
                        conv: 0,
                        source: 'Direct'
                    },
                    {
                        template_name: 'Seller Template Example',
                        type: 'Home Buyer',
                        unique: 3,
                        leads: 3,
                        conv: 6,
                        source: 'Facebook'
                    }
                ]


            }
        },
        methods: {
            chooseTemplate() {
                this.$router.push('/chooseTemplate')
            },
            templateViewModal(item) {
                console.log(item, 'item')
            },
            templateEditModal(item) {
                console.log(item, 'edit item')
            },
            templateDeleteModal(item) {
                console.log(item, 'delete item')
                this.deleteTemplate()
            },
            deleteTemplate() {
                this.$refs['template_delete'].toggle('#template_delete-btn')
            },
            removeTemplate() {
                console.log('delete')
                this.deleteTemplate()
            },
            getData(item) {
                if (item === null) {
                    return '_'
                } else {
                    return item
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;
        &.straight_length {
            max-width: 1700px;
            transition: .4s;
        }
    }
    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        &-item {
            display: flex;
            align-items: center;
            select {
                width: 260px;
                background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/8px 10px no-repeat;
            }
        }
        &-title {
            color: $main_blue;
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: uppercase;
        }
        &-new-btn {
            width: 286px;
            height: 44px;
            border-radius: 5px;
            background: $light_blue;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border: none;
            &:focus {
                box-shadow: none;
            }
            &:active {
                background: $main_blue !important;
            }
        }
        &-total-btn {
            width: 126px;
            height: 44px;
            border: 1px solid #00457B;
            border-radius: 22px;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: $main_blue;
            margin: 0 15px;
        }
    }
    &_content {
        width: 100%;
        padding: 10px 30px 0;

        ::v-deep table {
            border: 1px solid #dee2e6;

            td {
                vertical-align: middle;
            }
        }

        ::v-deep .table thead th {
            color: $main_blue;
            text-transform: uppercase;
            text-align: center;
        }

        & .item {
            &_name {
                color: $light_blue;
            }

            &_center {
                display: flex;
                justify-content: center;
            }
            &_switch {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_view {
                width: 30px;
                height: 20px;
                background: url("~@/assets/images/view_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }

            &_edit {
                width: 30px;
                height: 20px;
                background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }

            &_delete {
                width: 24px;
                height: 30px;
                background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }
        }
    }
}
.template-delete {
    &_modal_content {
        position: relative;
        padding: 0 20px 10px;
        width: 600px;

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #B4BABC;
        }

        & .input_content {
            display: flex;
            flex-direction: column;
        }

        & .delete_btn {
            background: $light_blue;
            box-shadow: 0 3px 6px #00000029;
            border-radius: 5px;
            width: 50%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 400;
            font-size: 18px;
            border: none;

            &-container {
                display: flex;
                justify-content: center;
                padding: 20px 0 5px;
            }
        }
    }

    &_message {
        padding: 20px 30px 40px;
        color: $grey;
        font-size: 22px;
    }

    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $main_blue;
        padding: 20px 0 10px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }

    &_description {
        padding: 15px 0;
        display: flex;
        width: 100%;
        min-height: 150px;
    }

    &_footer-container {
        display: flex;
        justify-content: center;
        padding: 20px 0 10px;
        width: 100%;
        height: 60px;
        background: url("~@/assets/images/rows.svg") center no-repeat;
        background-size: contain;
    }
}
::v-deep .modal-content {
    border: none !important;
    width: 640px;

    ::v-deep .modal-body {
        width: 620px !important;
    }
}
</style>